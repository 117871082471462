export const TRANSCRIPTS_QUERY_KEY = 'TRANSCRIPTS';
export const TRANSCRIPT_QUERY_KEY = 'TRANSCRIPT';
export const NETWORKS_QUERY_KEY = 'NETWORKS';
export const RADIO_NETWORK_QUERY_KEY = 'RADIO_NETWORK';
export const ALL_FREQUENCIES_KEY = 'ALL_FREQUENCIES';
export const ALL_NETWORKS_KEY = 'ALL_NETWORKS';
export const RADIO_NETWORKS_FILTER_QUERY_KEY = 'RADIO_NETWORKS_FILTER';
export const TRANSCRIPTS_FILTER_QUERY_KEY = 'TRANSCRIPTS_FILTER';
export const LOCATIONS_QUERY_KEY = 'LOCATIONS';
export const LOCATIONS_BY_IDS_QUERY_KEY = 'LOCATIONS_BY_IDS';
export const QR_CODE_QUERY_KEY = 'QR_CODE';
export const SUBSCRIPTIONS_QUERY_KEY = 'SUBSCRIPTIONS';
export const FACTIONS_QUERY_KEY = 'FACTIONS';
export const FACTION_BY_ID_QUERY_KEY = 'FACTION_BY_ID';
export const CATEGORIES_QUERY_KEY = 'CATEGORIES';
export const CATEGORIES_RECOMMENDED_QUERY_KEY = 'CATEGORIES_RECOMMENDED';
export const CATEGORY_TAGS_QUERY_KEY = 'TAGS';
export const ALL_INTERCEPTIONS_MAP_KEY = 'ALL_INTERCEPTIONS_MAP';
export const KML_DATA_KEY = 'KML_DATA';
export const NOTES_QUERY_KEY = 'NOTES_QUERY_KEY';
export const USER_DATA_QUERY_KEY = 'USER_DATA';
export const USER_ROLES_QUERY_KEY = 'USER_ROLES';
export const USERS_LIST_QUERY_KEY = 'USERS_LIST';
export const ACCESS_GROUPS_QUERY_KEY = 'GET_ACCESS_GROUPS';
export const STATISTICS_QUERY_KEY = 'STATISTICS_QUERY_KEY';
export const INTERCEPTIONS_ACTIVITY_QUERY_KEY = 'INTERCEPTIONS_ACTIVITY_QUERY_KEY';
export const DETAILS_QUERY_KEY = 'DETAILS';
export const CODES_QUERY_KEY = 'CODES_QUERY_KEY';
export const CALL_SIGNS_QUERY_KEY = 'CALL_SIGNS';
export const CALL_SIGN_BY_ID_QUERY_KEY = 'CALL_SIGN_BY_ID';
export const SUPPORT_QUERY_KEY = 'SUPPORT';
