import { AxiosError } from 'axios';
import isObject from 'lodash/isObject';
import { V3_PREFIX } from '@/shared/config';
import { NotificationProps } from '@/shared/context';
import { V3Error, APIVersionService } from '@/shared/types';

export class V3Service implements APIVersionService<V3Error> {
  isError(error: unknown): boolean {
    return (
      isObject(error) &&
      'response' in error &&
      isObject(error.response) &&
      'data' in error.response &&
      'request' in error.response &&
      isObject(error.response.data) &&
      'errors' in error.response.data &&
      Array.isArray(error.response.data.errors) &&
      isObject(error.response.request) &&
      'responseURL' in error.response.request &&
      typeof error.response.request.responseURL === 'string' &&
      error.response.request.responseURL.includes(V3_PREFIX)
    );
  }

  getErrors(error: unknown): V3Error[] {
    return error &&
      typeof error === 'object' &&
      'response' in error &&
      typeof error.response === 'object' &&
      error.response &&
      'data' in error.response &&
      typeof error.response.data === 'object' &&
      error.response.data &&
      'errors' in error.response.data &&
      Array.isArray(error.response.data.errors)
      ? error.response.data.errors
      : [];
  }

  getErrorDescription(v3Error: V3Error, validations: unknown): string | undefined {
    const v3ErrorDescription = v3Error.detail.split('.').reduce((accumulator, key) => {
      if (accumulator && typeof accumulator === 'object' && key in accumulator) {
        return (accumulator as Record<string, unknown>)[key];
      }
      return null;
    }, validations);

    if (typeof v3ErrorDescription === 'string') {
      return v3ErrorDescription;
    }
  }

  getAuthErrorDetail({ error, authErrors }: { error: AxiosError; authErrors: Set<string> }): string | undefined {
    const errors = this.getErrors(error);

    const v3AuthError = errors.find((v3Error) => {
      return 'detail' in v3Error && authErrors.has(v3Error.detail);
    });
    return v3AuthError?.detail;
  }

  getErrorPointer(error: V3Error): string | undefined {
    return error.source.pointer;
  }

  openErrorNotifications({
    error,
    validation,
    title,
    openNotification,
  }: {
    error: any;
    validation: unknown;
    title: string;
    openNotification: (args: NotificationProps) => void;
  }) {
    const errors = this.getErrors(error);

    errors.forEach((v3Error) => {
      if ('pointer' in v3Error.source) return; // pointer leads to specific field, if error contains pointer then custom error handler will be used.

      const description = this.getErrorDescription(v3Error, validation);

      if (!description) return;

      openNotification({
        type: 'error',
        title,
        description,
      });
    });
  }
}

export const v3Service = new V3Service();
